<template>
  <section class="my_home">
    <el-tabs 
      class="el_tabs"
      v-model="tab">
      <el-tab-pane 
        v-for="item in tabs"
        :key="item.label"
        :label="item.label" 
        :name="item.value"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="content">
      <component :is="coms[tab]"></component>
    </div>
  </section>
</template>

<script>
// import infos from './components/infos.vue'
// import member from './components/member.vue'
// import otherService from './components/otherService.vue'
// import changePassword from './components/changePassword.vue'
import collect from './components/collect.vue'
// import invite from './components/invite.vue'
export default {
  data () {
    return {
      tab: 'collect',
      tabs: [
        // {
        //   label: '个人信息',
        //   value: 'infos',
        // },
        {
          label: '自选',
          value: 'collect',
        },
        // {
        //   label: '邀请列表',
        //   value: 'invite',
        // },
        // {
        //   label: '会员服务',
        //   value: 'member',
        // },
        // {
        //   label: '增值服务',
        //   value: 'otherService',
        // },
        // {
        //   label: '重置密码',
        //   value: 'changePassword',
        // },
      ],
      coms: {
        // infos,
        collect,
        // changePassword,
        // member,
        // otherService,
        // invite
      }
    };
  },

  components: {},

  computed: {},

  methods: {},

  mounted() {}
}

</script>
<style lang='scss' scoped>
.my_home{
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  box-sizing: border-box;
  >*{
    width: 100%;
  }

  .el_tabs{
    padding: 0 30px;
  }
  .content{
    flex: 1;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    
    .tactics_item{
      margin-right: 30px;
      margin-bottom: 30px;
      cursor: pointer;
    }

    .el_empty{
      margin-top: 30px;
      width: 100%;
    }
  }
}
</style>